/* -------------------------------------------------------------------------------- */
/*  =Imports
/* -------------------------------------------------------------------------------- */

/* BassCSS */
@import 'normalize.css';
@import 'basscss-btn';
@import 'basscss-btn-outline';
@import 'basscss-btn-primary';
@import 'basscss-btn-sizes';
@import 'basscss-background-images';
@import 'basscss-background-colors';
@import 'basscss-colors';
@import 'basscss';
@import 'basscss-responsive-layout';
@import 'basscss-responsive-margin';
@import 'basscss-responsive-padding';
@import 'basscss-responsive-position';
@import '_custom-responsive-type-scale';
@import 'basscss-responsive-typography';

/* BassCSS negative margin fix */
@import '_margin_fix';

/* Variables */
@import '_vars';

/* Fonts */
@import '_font-league-spartan';

/* Sprites */
@import '_sprites';

/* Flag object */
@import '_flag';


/* -------------------------------------------------------------------------------- */
/*  =General
/* -------------------------------------------------------------------------------- */

html {
}
body {
  margin: 0;
  font-size: 100%;
  font-family: var(--main-font-family);
  line-height: 1.414;
  color: var(--black);
  transition: transform .4s cubic-bezier(0, 0, 0.2, 1);
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family);
}

::-moz-selection { background: #d6efff; }
::selection { background: #d6efff; }

a {
  color: var(--black);
  transition: color .4s;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  color: var(--gray);
}
b, strong {
  font-weight: 700;
}



/* -------------------------------------------------------------------------------- */
/*  =Icons and SVGs
/* -------------------------------------------------------------------------------- */

[class^="icon--"] {
  fill: currentColor;
  transition: fill 0.4s;
}
a:hover [class^="icon--"] {
  fill: currentColor;
}



/* -------------------------------------------------------------------------------- */
/*  =Helpers
/* -------------------------------------------------------------------------------- */

.light { font-weight: 300; }
.heading-font { font-family: var(--heading-font-family); }
.main-font { font-family: var(--main-font-family); }
.uc { text-transform: uppercase; }

[class*="border"] {
  border-color: #f1f1f1;
}



/* -------------------------------------------------------------------------------- */
/*  =Header
/* -------------------------------------------------------------------------------- */

.main-header {
  background-color: var(--white);
  background-size: cover;
  background-position: 50%;
  box-shadow: 0 0 20px 0 rgba(41,47,54,0.10);
}
.blog-logo img {
  transition: opacity .4s;
  max-height: 50px;
}
.blog-logo:hover img {
  opacity: 0.75;
}
.page-description {
  clear: both;
}
@media (--breakpoint-lg) {
  .blog-logo img {
    max-height: 88px;
  }
}



/* -------------------------------------------------------------------------------- */
/*  =Footer
/* -------------------------------------------------------------------------------- */

.copyright a,
.poweredby a {
  color: inherit;
}
.copyright a:hover,
.poweredby a:hover {
  color: var(--black);
}



/* -------------------------------------------------------------------------------- */
/*  =Navigation
/* -------------------------------------------------------------------------------- */

.toggle-nav {
  display: block;
  float: right;
  width: 32px;
  height: 25px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}
.toggle-nav span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--black);
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}
.toggle-nav:hover span,
.toggle-nav.open span {
  background-color: var(--gray);
}
.toggle-nav span:nth-child(1) { top: 0; }
.toggle-nav span:nth-child(2),
.toggle-nav span:nth-child(3) { top: 11px; }
.toggle-nav span:nth-child(4) { top: 22px; }
.toggle-nav.open span:nth-child(1) {
  top: 14px;
  width: 0%;
  left: 50%;
}
.toggle-nav.open span:nth-child(2) { transform: rotate(45deg); }
.toggle-nav.open span:nth-child(3) { transform: rotate(-45deg); }
.toggle-nav.open span:nth-child(4) {
  top: 14px;
  width: 0%;
  left: 50%;
}

.main-nav {
  position: absolute;
  width: 100%;
  background-color: var(--black);
  z-index: 1;
  top: 0;
  transform: translateY(-100%);
}
.main-nav ul li {
  opacity: 0;
  transform: translate3d(0, calc( var(--space-1) * -1), 0);
  transition: opacity .3s cubic-bezier(0, 0, 0.2, 1), transform .3s cubic-bezier(0, 0, 0.2, 1);
}
.nav-open .main-nav ul li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.nav-open .main-nav ul li:nth-child(1) { transition-delay: 0.2s; }
.nav-open .main-nav ul li:nth-child(2) { transition-delay: 0.3s; }
.nav-open .main-nav ul li:nth-child(3) { transition-delay: 0.4s; }
.nav-open .main-nav ul li:nth-child(4) { transition-delay: 0.5s; }
.nav-open .main-nav ul li:nth-child(5) { transition-delay: 0.6s; }
.nav-open .main-nav ul li:nth-child(6) { transition-delay: 0.7s; }
.nav-open .main-nav ul li:nth-child(7) { transition-delay: 0.8s; }
.nav-open .main-nav ul li:nth-child(8) { transition-delay: 0.9s; }
.nav-open .main-nav ul li:nth-child(9) { transition-delay: 1s; }
.nav-open .main-nav ul li:nth-child(10) { transition-delay: 1.1s; }
.nav-open .main-nav ul li:nth-child(11) { transition-delay: 1.2s; }
.nav-open .main-nav ul li:nth-child(12) { transition-delay: 1.3s; }
.nav-open .main-nav ul li:nth-child(13) { transition-delay: 1.4s; }
.nav-open .main-nav ul li:nth-child(14) { transition-delay: 1.5s; }
.nav-open .main-nav ul li:nth-child(15) { transition-delay: 1.6s; }
.nav-open .main-nav ul li:nth-child(16) { transition-delay: 1.7s; }
.nav-open .main-nav ul li:nth-child(17) { transition-delay: 1.8s; }
.nav-open .main-nav ul li:nth-child(18) { transition-delay: 1.9s; }
.nav-open .main-nav ul li:nth-child(19) { transition-delay: 2s; }
.nav-open .main-nav ul li:nth-child(20) { transition-delay: 2.1s; }



/* -------------------------------------------------------------------------------- */
/*  =Tag/Archive
/* -------------------------------------------------------------------------------- */

.tag-head {
  background-color: var(--black);
  background-position: 50%;
  background-size: cover;
}



/* -------------------------------------------------------------------------------- */
/*  =Author
/* -------------------------------------------------------------------------------- */

.author-profile {
  background-color: var(--black);
  background-position: 50%;
  background-size: cover;
}
.author-profile a {
  color: var(--silver);
}
.author-profile a:hover {
  color: var(--white);
}
.author-image img {
  width: 70px;
  height: auto;
}
@media (--breakpoint-sm) {
  .author-image img {
    width: 106px;
  }
}
@media (--breakpoint-lg) {
  .author-image img {
    width: 212px;
  }
}



/* -------------------------------------------------------------------------------- */
/*  =Post
/* -------------------------------------------------------------------------------- */

.post-header-inner {
  max-width: var(--width-4);
  margin-left: auto;
  margin-right: auto;
}
.post-header {
  padding: var(--space-3) var(--space-3) var(--space-2);
}
.page-template .post-header {
  margin-right: 0;
  padding: var(--space-3);
}
.post-title {
  margin-bottom: -14px;
}
@media (--breakpoint-sm) {
  .has-image .post-header-inner,
  .page-template .has-image .post-header {
    position: relative;
    margin-top: calc( var(--col-1) * -1);
  }
  .post-header {
    padding: var(--col-1) var(--col-1) 4%;
    margin-right: var(--col-1);
  }
  .page-template .post-header {
    padding: var(--col-1) var(--col-1);
  }
  .has-image .post-header {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgb(255, 255, 255) 60px, rgba(255,255,255,1) 100%);
    box-shadow: 0 -4px 4px 0 rgba(41, 47, 54, 0.08), 0 -20px 26px 0 rgba(41, 47, 54, 0.07);
  }
  .post-title {
    margin-bottom: -18px;
  }
}
@media (--breakpoint-lg) {
  .has-image .post-header {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgb(255, 255, 255) 106px, rgba(255,255,255,1) 100%);
  }
  .has-image .post-header-inner {
    margin-top: -7.25rem;
  }
  .post-title {
    margin-bottom: -26px;
  }
}

.post-meta {
  margin-left: var(--col-1);
}
.post-meta-author-image img {
  width: 96px;
  transition: opacity .4s;
}
.post-meta-author-image:hover img {
  opacity: .75;
}
@media (--breakpoint-lg) {
  .post-meta-author-image img {
    width: 106px;
  }
}

@media (--breakpoint-sm) {
  .read-next-story {
    border-right: 1px solid #f1f1f1;
  }
}

@media (--breakpoint-sm) {
  .post-comments {
    margin-left: 25%;
    padding: 0 8.33333%;
  }
}

/* Forms --------------------------------------- */

fieldset {
  border: 1px solid #f1f1f1;
  margin-bottom: var(--space-2);
  padding: var(--space-1) var(--space-2);
}
legend {
  margin-bottom: var(--space-2);
}
label {
  display: block;
  margin-bottom: var(--space-2);
}
input[type=text],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week],
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: 1.414;
  display: block;
  width: 100%;
  padding: 1.375rem;
  margin-bottom: 1.875rem;
  margin-top: var(--space-1);
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  outline-offset: -4px;
  outline-style: solid;
  outline-width: 1px;
  outline-color: transparent;
  transition: outline .6s;
  box-shadow: 0 1px 6px 0 rgba(41,47,54,.15), 0 5px 10px 0 rgba(41,47,54,0.025);
}
input[type=text]:focus,
input[type=date]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=month]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=week]:focus,
textarea:focus {
  outline-width: 1px;
  outline-color: var(--blue);
}
input {
  height: 4rem;
}
input[type=radio],
input[type=checkbox],
input[type=file] {
  height: auto;
}
input[type=file] {
  width: 100%;
}
textarea {
  resize: none;
}
input[type=search] {
  box-sizing: border-box;
}

.btn {
  transition: box-shadow .4s;
}
.btn-primary:hover {
  box-shadow: inset 0 0 0 20rem var(--darken-2);
}
.btn:focus {
  border-color: transparent;
  box-shadow: inset 0 0 0 20rem var(--darken-2);
}
input[type=button],
input[type=submit],
input[type=reset],
button {
  @extend .btn;
  @extend .btn-primary;
}

.subscribe form {
  display: flex;
  align-items: stretch;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
}
.subscribe .form-group {
  flex: 1 1 auto;
}
.subscribe .subscribe-email {
  margin-bottom: 0;
  outline-color: var(--white);
  margin-top: 0;
}
.subscribe button {
  flex: 0.5 1 auto;
  border-radius: 0;
}



/* -------------------------------------------------------------------------------- */
/*  =Content
/* -------------------------------------------------------------------------------- */

.post-content {
  padding: 0 var(--space-3);
}
.post-content > p:first-child,
.post-content .kg-card-markdown > p:first-child {
  margin-top: 0;
  color: var(--gray);
  font-size: var(--h5);
  font-weight: 400;
}
.post-content > :first-child > img,
.post-content .kg-card-markdown > :first-child img {
  margin-top: 0;
}
@media (--breakpoint-sm) {
  .post-content {
    padding: 0 var(--col-1);
  }
  .post-content > p:first-child,
  .post-content .kg-card-markdown > p:first-child {
    font-size: var(--h4);
    font-weight: inherit;
  }
}
@media (--breakpoint-lg) {
  .post-content > p:first-child,
  .post-content .kg-card-markdown > p:first-child {
    font-size: var(--h3);
  }
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin-top: var(--space-3);
  margin-bottom: var(--space-2);
}
.post-content h1 { font-size: var(--h3); }
.post-content h2 { font-size: var(--h4); }
.post-content h3 { font-size: var(--h5); }
.post-content h4 { font-size: var(--h6); }
.post-content h5 { font-size: var(--h6); }
.post-content h6 { font-size: var(--h6); }

.post-content p,
.post-content dl,
.post-content ul,
.post-content ol,
.post-content table { font-size: var(--h5); }

.post-content dl,
.post-content ul,
.post-content ol { padding-left: 2em; }

.post-content li,
.post-content dd {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}
.post-content dd {
  margin-left: var(--space-3);
}

.post-content table {
  width: 100%;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  border-spacing: 0;
}
.post-content th,
.post-content td {
  padding: var(--space-1) var(--space-2);
  border-bottom: 1px solid #f1f1f1;
}
.post-content th {
  text-align: left;
}
.post-content tr:nth-child(even) td {
  background-color: #f1f1f1;
}

.post-content pre,
.post-content code {
  background: #f1f1f1;
  font-size: .875rem;
}
.post-content pre {
  padding: var(--space-2);
  overflow-x: auto;
}
.post-content code {
  padding: .1875rem;
  word-wrap: break-word;
}
.post-content pre code {
  padding: 0;
}

.post-content blockquote {
  margin-left: var(--space-3);
  margin-right: var(--space-3);
  color: var(--gray);
}

.post-content hr {
  margin: var(--space-3) 0;
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f1f1f1;
}

.post-content a {
  text-decoration: underline;
}
.post-content .read-more {
  color: inherit;
  border-bottom: 0;
  text-decoration: none;
}
.post-content .read-more:hover {
  color: var(--black);
}
.post-content img {
  display: block;
  max-width: 100%;
  margin: var(--space-3) 0 2.5rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 8px 0 rgba(41,47,54,0.15), 0 20px 48px 0 rgba(41,47,54,0.30);
}
.post-content img + br + small,
.post-content .kg-image-card figcaption {
  display: block;
  margin-top: calc(var(--space-3) * -1);
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: var(--h6);
  color: var(--gray);
}
.post-content .kg-image-card figcaption {
  margin-top: calc(var(--space-2) * -1);
}
.post-content .kg-embed-card,
.post-content .kg-image-card {
  margin: 0;
}
.post-content .kg-image-card.kg-width-full {
  width: 100vw;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}
.post-content .kg-image-card.kg-width-full img {
  max-width: 100%;
  border-radius: 0;
}
@media (--breakpoint-sm) {
  .post-content img {
    position: relative;
    left: 50%;
    max-width: 115%;
    height: auto;
    transform: translate(-50%);
  }
  .post-content .kg-image-card.kg-width-wide img {
    max-width: 133%;
  }
  .post-template .post-content .kg-image-card.kg-width-full {
    margin-left: calc(33.333% - 50vw);
  }
  .post-content .kg-image-card.kg-width-full figcaption {
    text-align: left;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .post-content blockquote,
  .post-content blockquote p {
    font-size: var(--h4);
  }
}
@media (--breakpoint-lg) {
  .post-content img {
    max-width: 746px;
    margin: var(--space-4) 0;
  }
  .page-template .post-content img {
    max-width: 852px;
  }
  .post-content .kg-image-card.kg-width-wide img {
    max-width: 852px;
  }
  .post-content img + br + small,
  .post-content .kg-image-card figcaption {
    margin-top: calc(var(--space-4) * -1);
    margin-bottom: var(--space-3);
    font-size: var(--h5);
  }
  .post-content .kg-image-card figcaption {
    margin-top: calc(var(--space-3) * -1);
  }
  .post-content .kg-image-card.kg-width-full figcaption {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (--breakpoint-sm) {
  .post-content h1 { font-size: var(--h2); }
  .post-content h2 { font-size: var(--h3); }
  .post-content h3 { font-size: var(--h4); }
  .post-content h4 { font-size: var(--h5); }
}
@media (--breakpoint-lg) {
  .post-content h1 { font-size: var(--h1); }
  .post-content h2 { font-size: var(--h2); }
  .post-content h3 { font-size: var(--h3); }
  .post-content h4 { font-size: var(--h4); }
  .post-content h5 { font-size: var(--h5); }

  .post-content p,
  .post-content dl,
  .post-content ul,
  .post-content ol,
  .post-content table {
    font-size: var(--h4);
  }

  .post-content pre,
  .post-content code {
    font-size: 1.125rem;
  }
  .post-content pre {
    padding: var(--space-3);
  }
  .post-content code {
    padding: .25rem;
  }

  .post-content blockquote,
  .post-content blockquote p {
    font-size: var(--h3);
  }
}
