.icon--arrow-left {
	width: 24px;
	height: 24px;
}

.icon--arrow-right {
	width: 24px;
	height: 24px;
}

.icon--book {
	width: 20px;
	height: 20px;
}

.icon--chevron-left {
	width: 20px;
	height: 38px;
}

.icon--chevron-right {
	width: 20px;
	height: 38px;
}

.icon--earth {
	width: 32px;
	height: 32px;
}

.icon--facebook-square {
	width: 24px;
	height: 28px;
}

.icon--feed {
	width: 22px;
	height: 22px;
}

.icon--file-text2 {
	width: 32px;
	height: 32px;
}

.icon--google-plus {
	width: 36px;
	height: 28px;
}

.icon--link {
	width: 32px;
	height: 32px;
}

.icon--linkedin {
	width: 24px;
	height: 28px;
}

.icon--location {
	width: 32px;
	height: 32px;
}

.icon--map-marker {
	width: 20px;
	height: 20px;
}

.icon--pinterest-p {
	width: 20px;
	height: 28px;
}

.icon--reddit {
	width: 32px;
	height: 32px;
}

.icon--search {
	width: 32px;
	height: 32px;
}

.icon--twitter {
	width: 26px;
	height: 28px;
}

.icon--vimeo {
	width: 58px;
	height: 50px;
}

